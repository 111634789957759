.loader {
    margin: 0 auto;
    text-align: center;
    padding-top: 120px ;
}
.loader img {
    margin: 0 auto;
    animation: spinning 1.2s infinite linear;
}

@keyframes spinning {

    from {

        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);

    }

}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
}

.container{
  margin: 0 auto;
  padding: 0 30px;
}
.generic-btn {
  padding: 20px 25px;
  border-radius: 50px;
  width: max-content;
  letter-spacing: 4px;
  font-weight: bold;
  font-size: 17px;
  transition: all 200ms ease;
  outline: 4px solid transparent;
  outline-offset: 4px;
}
.generic-btn-1 {
  padding: 18px 30px;
  background-color: #FFBC14;
  color: #080808;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 5px;
  outline: none ;
  transition: all 300ms ease;
  z-index: 5;
  position: relative;
}
.generic-btn:hover {
  outline: 4px solid #FFBC14;
  color: #080808;
  background-color: white;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-heading h6 {
  color: #FFBC14;
  font-weight: 800;
  letter-spacing: 2px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.section-heading h1 {
  color: #080808;
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 0;
}

.section-spacing{
  padding: 100px 0;
}

.whatsapp-cta {
  bottom: 30px;
  position: fixed;
  right: 30px;
  z-index: 5;
}